import { navigate } from "gatsby-link";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../store";
import { DataSenderProps, sendData } from "../../../store/DataSender/actions";
import { resetDataStates, setErrors } from "../../../store/DataSender/reducer";
import {
  selectDataSenderErrors,
  selectDataSenderLoading,
  selectSuccessfullySentData,
} from "../../../store/DataSender/selector";
import { resetSurvey } from "../../../store/Surveys/reducer";
import "../../../styles/fields.scss";
import { checkRequiredFields, sendToast } from "../../../utils/helpers";
import DefaultButton from "../../Buttons/DefaultButton";
import LoadingSmall from "../../Loading/LoadingSmall";
import useDefaultFormBuilder from "../FormHook/defaultFormHook";
import { getFields, getKeysFromStatusOptions } from "./config";

const DataSenderContainer = () => {
  const dispatch = useAppDispatch();
  const [config, setConfig] = useState(null) as any[];
  const [fields, data] = useDefaultFormBuilder({ config });
  const success = useSelector(state =>
    selectSuccessfullySentData(state)
  ) as boolean;
  const loading = useSelector(state =>
    selectDataSenderLoading(state)
  ) as boolean;
  const errors = useSelector(state => selectDataSenderErrors(state));

  useEffect(() => {
    if (success) {
      dispatch(resetDataStates());
      dispatch(setErrors(null));
      navigate("/success/", {
        state: {
          type: "DataSender",
        },
      });
    }
  }, [success]);

  useEffect(() => {
    if (errors) {
      const errorString = errors.join(", ");
      sendToast(errorString, { type: "error" });
      dispatch(resetSurvey());
    }
  }, [errors]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search) as any;
      const entries = [...urlParams.entries()];

      const { agentInfo, vendor, vid } = entries.reduce(
        (acc, [key, value]) => {
          if (key.includes("agent_")) {
            acc.agentInfo[key] = value;
          } else {
            acc[key] = value;
          }
          return acc;
        },
        { agentInfo: {} }
      );

      if (!vid) {
        toast("No Vid was attached");
        navigate('/404/', {
          state: {
            error: "There was an error with your form. Please contact a Clever representative at 833-7-CLEVER",
          },
        });
      } else {
        // TODO: get info from customer vid
        setConfig(getFields(vendor, agentInfo));
      }
    }
  }, []);

  const handleFormSubmit = () => {
    const { invalid, requiredLabelName } = checkRequiredFields(config, data);
    if (!invalid) {
      const urlParams = new URLSearchParams(window.location.search);
      const vid = urlParams.get("vid");
      const dataFields = {
        ...data,
        vid,
        status: getKeysFromStatusOptions(data.status),
        working_with_clever: data.working_with_clever === "Yes",
      } as DataSenderProps;
      dispatch(sendData(dataFields));
    } else {
      sendToast(`Please fill out the following field: ${requiredLabelName}`, {
        type: "error",
      });
    }
  };

  return (
    <div className="defaultFormContainer">
      <h2>Data Sender</h2>
      <div className="defaultFormBody">
        <p>Enter all the fields below and click Submit.</p>
        <div>
          {fields}
          <DefaultButton
            text={loading ? <LoadingSmall /> : "submit"}
            className="defaultButton"
            callback={() => handleFormSubmit()}
          />
        </div>
      </div>
    </div>
  );
};

export default DataSenderContainer;
