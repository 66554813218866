import React from "react";
import AuthenticationLayout from "../components/AuthenticationLayout";
import DataSenderContainer from "../components/FormBuilder/DataSenderContainer/DataSenderContainer";
import "../styles/form.scss";

const DataSender = () => (
  <AuthenticationLayout>
    <DataSenderContainer />
  </AuthenticationLayout>
);

export default DataSender;
